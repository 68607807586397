export default class LocationsMap {
  constructor() {
    document.addEventListener('DOMContentLoaded', (event) => {
      this.mapEl = document.querySelector('[data-locations-map]');
      if (this.mapEl) {
        this.map = null;

        let iconBaseWidth = 46;
        this.settings = {
          center: [0, 0],
          zoom: 13,
          scrollWheelZoom: false,
          icon: {
            sizeX: iconBaseWidth,
            sizeY: iconBaseWidth * 74 / 46,
          },
          markers: [
            {
              lat: 49.246336,
              long: 8.642718,
              path: this.mapEl.getAttribute('data-locations-map-pin-primary'),
              popup: this.mapEl.getAttribute('data-locations-map-popup-rot'),
            },
            {
              lat: 49.29523,
              long: 8.64106,
              path: this.mapEl.getAttribute('data-locations-map-pin-cyan'),
              popup: this.mapEl.getAttribute('data-locations-map-popup-walldorf'),
            },
          ],
        };

        // Check for Leaflet existence
        if (typeof window.L === 'undefined' || typeof window.L.map === 'undefined') {
          // Create a new script element
          let script = document.createElement('script');
          script.src = 'https://unpkg.com/leaflet@1.3.4/dist/leaflet.js';
          script.integrity = 'sha512-nMMmRyTVoLYqjP9hrbed9S+FzjZHW5gY1TWCHA5ckwXZBadntCNs8kEqAWdrb9O7rxbCaA4lKTIWjDXZxflOcA==';
          script.crossOrigin = '';

          // Create a new stylesheet element
          let stylesheet = document.createElement('link');
          stylesheet.type = 'text/css';
          stylesheet.rel = 'stylesheet';
          stylesheet.href = 'https://unpkg.com/leaflet@1.3.4/dist/leaflet.css';
          stylesheet.integrity = 'sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA==';
          stylesheet.crossOrigin = '';

          // Load the map when the script has loaded
          script.onload = this.initMap.bind(this);

          // Add script and stylesheet to the DOM
          document.body.appendChild(script);
          document.head.appendChild(stylesheet);
        } else {
          this.initMap();
        }
      }
    });
  }

  initMap() {
    // Create new Leaflet Map instance.
    this.map = window.L.map(this.mapEl, this.settings);

    // Add tile layer
    window.L.tileLayer('https://c.tile.openstreetmap.de/{z}/{x}/{y}.png', {
      attribution: 'Map data © <a href="http://openstreetmap.de">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    this.markerLatLngsArray = this.settings.markers.map((marker) => {
      // Add map pin
      let mapIcon = window.L.icon({
        iconUrl: marker.path,
        iconSize: [this.settings.icon.sizeX, this.settings.icon.sizeY],
        iconAnchor: [this.settings.icon.sizeX / 2, this.settings.icon.sizeY],
        popupAnchor: [0, -this.settings.icon.sizeY],
      });

      // Set a new marker
      let markerLeaflet = window.L.marker(
        [
          marker.lat,
          marker.long,
        ],
        {
          icon: mapIcon,
        }
      ).addTo(this.map);

      // Popup (optional)
      if (marker.popup) {
        markerLeaflet.bindPopup(marker.popup);
      }

      return markerLeaflet.getLatLng();
    });

    this.fitBounds();
    this.map.on({
      resize: this.fitBounds.bind(this),
    });
  }

  fitBounds() {
    if (this.markerLatLngsArray) {
      this.map.fitBounds(this.markerLatLngsArray, {
        padding: [84, 84],
      });
    }
  }
}
