import Parallax from 'parallax-js';

document.addEventListener('DOMContentLoaded', () => {
  let heroAnimationEl = document.querySelector('[data-hero-animation]');
  if (heroAnimationEl && window.getComputedStyle(document.body).overflowX === 'hidden') {
    // Parallax on mousemove
    let parallaxContainer = heroAnimationEl.querySelector('[data-hero-animation-container]');
    if (parallaxContainer) {
      new Parallax(parallaxContainer, {
        relativeInput: true,
        clipRelativeInput: true,
      });
    }
  }
});
