//
// Main entry file
// Should be inserted right above </body>
// --------------------------------------------------

// Webpack entries
// Styles
import '../css/app.scss';
// Images
import './webpack/require-img';
// SVGs
import './webpack/require-svg';

// SVG <use> polyfill
import 'svgxuse';

// Manually load some polyfills based on `useBuiltIns: usage`
Array.from;
Object.assign;

// JS module imports
import './modules/fontfaceobserver';
import './modules/object-fit-images';
import './modules/scrollbar';
import './modules/anchornav';
import './modules/toplink';
import Overlays from './modules/overlays';
import Menues from './modules/menu';
import Galleries from './modules/gallery';
import SiteSwitch from './modules/site-switch';
import LocationsMap from './modules/locations-map';
import Share from './modules/share';
import Panels from './modules/panels';

import './modules/hero-animation';
import './modules/footer-animation';

new Overlays();
new Menues();
new SiteSwitch();
new LocationsMap();
new Galleries();
new Share();
new Panels();
