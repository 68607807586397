import Util from './util';

export default class SiteSwitch {
  constructor() {
    this.OPEN_FLAG = 'is-open';
    this.el = document.querySelector(`[data-site-switch]`);

    if (this.el) {
      this.logos = [...document.querySelectorAll(`[data-site-switch-logo]`)];
      this.initTogglers();
      this.initOutsideClick();

      // close overlay on escape press
      Util.esc(this.close.bind(this));

      return this;
    }

    return false;
  }

  initTogglers() {
    this.togglers = [...this.el.querySelectorAll(`[data-site-switch-toggle]`)];
    this.togglers.forEach((toggler, idx) => {
      toggler.addEventListener('click', (e) => {
        this.toggle();
        e.preventDefault();
        e.currentTarget.blur();
      });
    });
  }

  // close overlay on click "anywhere"
  initOutsideClick() {
    document.addEventListener('click', (e) => {
      if (this.el !== e.target && !this.el.contains(e.target)) {
        this.close();
      }
    });
  }

  toggle() {
    this.isOpen() ? this.close() : this.open();
  }

  isOpen() {
    return this.el.classList.contains(this.OPEN_FLAG);
  }

  open() {
    this.el.classList.add(this.OPEN_FLAG);
  }

  close() {
    this.el.classList.remove(this.OPEN_FLAG);
  }
}
