import SmoothScroll from 'smooth-scroll';

document.addEventListener('DOMContentLoaded', (event) => {
  new SmoothScroll('[data-toplink]', {
    speed: 300,
  });
});


document.addEventListener('scrollStart', (e) => {
  e.detail.toggle.blur();
  e.detail.toggle.classList.add('is-scrolling');
});

document.addEventListener('scrollStop', (e) => {
  e.detail.toggle.classList.remove('is-scrolling');
});
document.addEventListener('scrollCancel', (e) => {
  e.detail.toggle.classList.remove('is-scrolling');
});
