import Glide from '@glidejs/glide';
import ArrowDisabler from './GlideArrowDisabler';
import BigPicture from 'bigpicture';


class Gallery {
  constructor(el) {
    this.el = el;
    this.slides = [...this.el.querySelectorAll('[data-gallery-slide]')];
    this.galleryImages = this.el.querySelectorAll('[data-bp]')

    this.initSlider();
    this.initLightboxes();
  }

  initSlider() {
    new Glide(this.el, {
      bound: true,
      gap: 48,
      perView: 2,
      rewind: false,
      breakpoints: {
        1023: {
          gap: 48,
          perView: 1,
        },
        767: {
          gap: 20,
          perView: 1,
        }
      }
    }).mount({ ArrowDisabler });
  }

  initLightboxes() {
    this.slides.forEach((slide) => {
      slide.addEventListener('click', (e) => {
        if (!this.el.classList.contains('glide--dragging')) {
          e.preventDefault();
          BigPicture({
            el: e.currentTarget.querySelector('[data-bp]'),
            gallery: this.galleryImages,
          });
        }
      });
    });
  }
}

export default class Galleries {
  constructor() {
    document.addEventListener('DOMContentLoaded', (e) => {
      this.galleries = [...document.querySelectorAll('[data-gallery]')].map((el) => new Gallery(el));
    });
  }
}
