import VanillaScrollspy from './vanillajs-scrollspy';
import 'intersection-observer';
import Stickyfill from 'stickyfilljs';

document.addEventListener('DOMContentLoaded', (e) => {
  const anchornavWrapper = document.querySelector('[data-anchornav]');
  if (anchornavWrapper) {
    const anchornav = anchornavWrapper.querySelector('[data-anchornav-nav]');

    if (anchornav) {
      // Init toggler
      const toggler = anchornavWrapper.querySelector('[data-anchornav-toggle]');
      if (toggler) {
        const TOGGLE_OPEN_FLAG = 'is-open';
        toggler.addEventListener('click', (e) => {
          anchornavWrapper.classList.contains(TOGGLE_OPEN_FLAG)
          ? anchornavWrapper.classList.remove(TOGGLE_OPEN_FLAG)
          : anchornavWrapper.classList.add(TOGGLE_OPEN_FLAG);

          e.preventDefault();
          e.currentTarget.blur();
        });
      }

      // Set up `stuck`-state
      if (('IntersectionObserver' in window)) {
        const STUCK_FLAG = 'is-stuck';
        let sentinelTop = document.querySelector('[data-anchornav-sentinel="top"]');
        let sentinelBottom = document.querySelector('[data-anchornav-sentinel="bottom"]');
        if (sentinelTop && sentinelBottom) {
          let observer = new IntersectionObserver(({0: entry}) => {
            // check anchornav position
            anchornavWrapper.getBoundingClientRect().top <= 0
             ? anchornavWrapper.classList.add(STUCK_FLAG)
             : anchornavWrapper.classList.remove(STUCK_FLAG);
          });

          observer.observe(sentinelTop)
          observer.observe(sentinelBottom);
        }
      }

      // Build anchor list
      let listUL = anchornav.querySelector('ul');
      let headings = [...document.querySelectorAll('[data-anchornav-section]')];

      if (listUL && headings.length) {
        // Dynamically build li elements
        headings.forEach((elem) => {
          listUL.innerHTML += `<li><a href='#${elem.id}'>${elem.getAttribute('data-anchornav-section')}</a></li>`;
        });

        // blur anchornav links after click
        [...anchornav.querySelectorAll('a')].forEach((link) => {
          link.addEventListener('click', (e) => e.currentTarget.blur());
        });

        // Scroll spy
        const scrollspy = new VanillaScrollspy(anchornav, 500, 'easeOutSine');
        scrollspy.init();

        // Sticky polyfill
        Stickyfill.addOne(anchornavWrapper);
      }
    }
  }
});
