import Util from './util';

class Overlay {
  constructor(el) {
    this.el = el;
    this.id = this.el.id;
    this.innerEl = this.el.querySelector('[data-overlay-inner]');
    this.initTogglers();
    this.initOutsideClick();

    // close overlay on escape press
    Util.esc(this.close.bind(this));

    return this;
  }

  initTogglers() {
    this.openers = [...document.querySelectorAll(`[data-overlay-opener="${this.id}"]`)];
    this.closers = [...document.querySelectorAll(`[data-overlay-closer="${this.id}"]`)];

    // Openers
    this.openers.forEach((opener, idx) => {
      opener.addEventListener('click', (e) => {
        this.open();
        e.preventDefault();
        e.stopPropagation();
        return false;
      }, false);
    });

    // Closers
    this.closers.forEach((closer, idx) => {
      closer.addEventListener('click', (e) => {
        this.close();
        e.preventDefault();
        e.stopPropagation();
        return false;
      }, false);
    });
  }

  // close overlay when click wasn't inside `overlay-inner`
  initOutsideClick() {
    document.addEventListener('click', (e) => {
      if (this.innerEl !== e.target && !this.innerEl.contains(e.target)) {
        this.close();
      }
    });
  }

  // Opens overlay
  open() {
    this.el.classList.add('is-open');
  }

  // Closes overlay
  close() {
    this.el.classList.remove('is-open');
  }
}


export default class Overlays {
  constructor() {
    document.addEventListener('DOMContentLoaded', (e) => {
      this.overlays = [...document.querySelectorAll('[data-overlay]')].map((el) => {
        return new Overlay(el);
      });
    });
  }
}
